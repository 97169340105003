import React, { Component } from 'react';
import styled from 'styled-components';

import { vars } from '../lib/theme';

import LoadingSpin from './LoadingSpin';

const LoadingSpinner = props => {
  const [hidden, setHidden] = React.useState(true);
  React.useEffect(() => {
    const timer = setTimeout(() => setHidden(false), 0);
    return () => {
      clearTimeout(timer);
    };
  }, [props]);

  // return <Root className={props.className}>{hidden ? '' : <ReactLoading type="spin" color={vars.colors.primary.mid} height={30} width={30} />}</Root>;
  return (
    <Root className={props.className}>
      {hidden ? (
        ''
      ) : (
        <LoadingSpin color={vars.colors.primary.mid} height={30} width={30} />
      )}
    </Root>
  );
};

export default LoadingSpinner;

// Styles

const Root = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
`;
