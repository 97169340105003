import React from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import styled, { css } from 'styled-components';
import { Cols, Col } from '@elwell/blocko';

import { vars, IconPlay, IconMusic, IconTab, IconInfo } from '../lib/theme';
import { BgImg } from '../lib/styles';

import LessonMeta from './LessonMeta';
import InstructorItem from './InstructorItem';
import Tag from './Tag';
// import LoadingSpinner from './LoadingSpinner';
import Button from './Button';

const Modal = dynamic(() => import('./Modal'), { ssr: false });
// const VideoPlayer = dynamic(() => import('./VideoPlayer'), { ssr: false, loading: () => <LoadingSpinner /> });

const LessonItemBox = ({
  id,
  slug,
  heading,
  title,
  image,
  subjects,
  height,
  description,
  videos,
  instructors,
  hasTab,
  hasBackingTrack,
  videoCount,
  duration,
  showDesc = true,
  className,
}) => {
  const component = (
    <Wrap>
      <Inner height={height}>
        {heading && <Heading>{heading}</Heading>}
        <BgImg
          srcSet={image?.srcSet}
          src={!image ? '/static/placeholder.jpg' : undefined}
          alt={title}
        />
        <Overlay />
        <InnerContent>
          {hasTab && (
            <Tag
              css={css`
                margin-right: ${vars.spacing[1]};
              `}
              theme="primary"
            >
              <IconTab
                size={10}
                css={css`
                  position: relative;
                  top: -1px;
                  margin-right: ${vars.spacing[0]};
                `}
              />
              Tabs
            </Tag>
          )}
          {hasBackingTrack && (
            <Tag
              css={css`
                margin-right: ${vars.spacing[1]};
              `}
              theme="primary"
            >
              <IconMusic
                size={10}
                css={css`
                  position: relative;
                  top: -1px;
                  margin-right: ${vars.spacing[0]};
                `}
              />
              Backing Track
            </Tag>
          )}
          <Link href="/lesson/[lessonSlug]" as={`/lesson/${slug}`}>
            <a>
              <Title dangerouslySetInnerHTML={{ __html: title }}></Title>
            </a>
          </Link>
        </InnerContent>
      </Inner>
      <Content>
        <InstructorItem
          css={`
            margin-bottom: ${vars.spacing[1]};
          `}
          instructor={instructors && instructors[0]}
          avatarSize={40}
        >
          <LessonMeta
            css={`
              font-size: 0.8rem;
            `}
            videoCount={videoCount}
            duration={duration}
          />
        </InstructorItem>
        {showDesc && <Desc dangerouslySetInnerHTML={{ __html: description }} />}
      </Content>
      <div
        css={css`
          margin-top: auto;
          padding: ${vars.spacing[2]};
        `}
      >
        <Cols space={0.125}>
          <Col width={1}>
            <Link
              href="/lesson/[lessonSlug]/[videoSlug]"
              as={`/lesson/${slug}/${videos[0].slug}`}
            >
              <a>
                <Button theme="success" block flat>
                  Start Lesson
                  <IconPlay
                    css={css`
                      position: relative;
                      top: -1px;
                      margin-left: ${vars.spacing[0]};
                    `}
                  />
                </Button>
              </a>
            </Link>
          </Col>
          <Col width={1}>
            <Link href="/lesson/[lessonSlug]" as={`/lesson/${slug}`}>
              <a>
                <Button theme="light" block flat>
                  More Info
                  <IconInfo
                    css={css`
                      position: relative;
                      top: -1px;
                      margin-left: ${vars.spacing[0]};
                    `}
                  />
                </Button>
              </a>
            </Link>
          </Col>
        </Cols>
      </div>
    </Wrap>
  );

  return <Root className={className}>{component}</Root>;
};

export default LessonItemBox;

// Styles

const Root = styled('div')`
  overflow: hidden;
  position: relative;
  height: 100%;
  border-radius: ${vars.borderRadius[2]};
  box-shadow: ${vars.shadows[1]};
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: ${vars.shadows[0]};
  }
`;

const Wrap = styled('div')`
  overflow: hidden;
  background-color: ${vars.colors.white};
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Inner = styled('div')`
  position: relative;
  background-color: ${vars.colors.black};
  padding-top: ${({ height }) => height || '62%'};
`;

const Overlay = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    10deg,
    rgba(0, 0, 0, 0.75) 15%,
    rgba(0, 0, 0, 0.1) 50%
  );
`;

const InnerContent = styled('div')`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${vars.spacing[2]};
  text-align: left;
`;

const Heading = styled('h6')`
  color: ${vars.colors.white};
  position: absolute;
  top: ${vars.spacing[1]};
  left: ${vars.spacing[1]};
  border-radius: ${vars.borderRadius[0]};
  padding: ${vars.spacing[1]};
  z-index: 1;
  text-align: center;
  background-color: ${vars.colors.primary.mid};
  opacity: 0.9;
`;

const Title = styled('h5')`
  margin-top: ${vars.spacing[0]};
  color: ${vars.colors.white};
`;

const Content = styled('div')`
  margin-top: ${vars.spacing[2]};
  /* margin-top: -${vars.spacing[1]}; */
  padding: 0 ${vars.spacing[2]};
  text-align: left;
`;

const Desc = styled('div')`
  color: ${vars.colors.primary.darkest};
  font-size: 0.8em;
  margin-bottom: 0;
`;
