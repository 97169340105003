import React from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';

import { vars } from '../lib/theme';

import Avatar from './Avatar';

const InstructorItem = ({ className, instructor, avatarSize, children }) => {
  return (
    <Link href={`/instructor/[instructorSlug]`} as={`/instructor/${instructor.slug}`}>
      <a>
        <Instructor className={className}>
          <Avatar srcSet={instructor?.image?.srcSet} url={instructor?.image?.src} size={avatarSize} />
          <InstructorContent>
            <Title>{instructor.title}</Title>
            {children}
          </InstructorContent>
        </Instructor>
      </a>
    </Link>
  );
};

export default InstructorItem;

// Styles

const Root = styled('ul')`
  margin: 0;
  padding: 0;
  list-style-type: none;
  line-height: 1.2;
`;

const InstructorListItem = styled('li')``;

const Instructor = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const InstructorContent = styled('div')`
  margin-left: ${vars.spacing[1]};
`;

const Title = styled('h5')`
  margin: 0;
  /* line-height: 1; */
`;
