import React from 'react';

import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';

const Layout = ({ children }) => {
  return (
    <>
      <SiteHeader />
      {children}
      <SiteFooter />
    </>
  );
};

export default Layout;
