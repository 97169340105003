import styled from 'styled-components';

import { vars } from '../lib/theme';

const Avatar = ({ size = 50, url, srcSet, alt, ...rest }) => (
  <Ring size={size} {...rest}>
    {srcSet ? <Img srcSet={srcSet} alt={alt} /> : <Img src={url || '/static/icon-account.svg'} alt={alt} />}
  </Ring>
);

export default Avatar;

// Styles
const Ring = styled('div')`
  position: relative;
  width: ${props => props.size || 40}px;
  height: ${props => props.size || 40}px;
  /* padding: ${props => props.size / 20 || 3}px; */
  border-radius: 50%;
  /* background-color: ${vars.colors.secondary.mid}; */
  background: linear-gradient(40deg, ${vars.colors.tertiary.mid}, ${vars.colors.primary.mid});
  /* &:before {
    display: block;
    content: '';
    position: absolute;
    top: ${props => props.size / 40 || 2}px;
    right: ${props => props.size / 40 || 2}px;
    bottom:${props => props.size / 40 || 2}px;
    left: ${props => props.size / 40 || 2}px;
    background-color: ${vars.colors.white};
    border-radius: 50%;
  } */
`;

const Img = styled('img')`
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  background-color: ${vars.colors.secondary.mid};
  position: relative;
  overflow: hidden;
`;
