import React from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';

import { vars } from '../lib/theme';
import { Container } from '../lib/styles';

// import SectionCta from './SectionCta';
import FooterCta from './FooterCta';
// import PopupToolkit from './PopupToolkit';
import useUser from './useUser';

const SiteFooter = () => {
  const user = useUser();

  return (
    <Root>
      {/* {!user && <FooterCta />} */}
      {/* {!user && <PopupToolkit />} */}
      {/* <SectionCta heading="Start your 14-day free trial" body="Create your account now for full instant access and pay nothing for 14 days." link="/join" buttonText="Get started" /> */}
      <Sep />
      <FooterMain>
        <FooterMainDesc>
          <img
            css={css`
              width: 300px;
              margin-bottom: ${vars.spacing[2]};
            `}
            src="/static/logo-school-of-mcrock.svg"
            alt="School of McRock Logo"
          />
          <p>Join us and learn all of the tips, tricks and insights you'll need to play the guitar better than you ever thought possible.</p>
        </FooterMainDesc>
        <FooterMainNav>
          <h4>About Us</h4>
          <ul className="list-unstyled">
            <li>
              <Link href="/contact">
                <a>Contact Us</a>
              </Link>
            </li>
            <li>
              <Link href="/join">
                <a>Join Now</a>
              </Link>
            </li>
            <li>
              <Link href="/lessons">
                <a>Guitar Lessons</a>
              </Link>
            </li>
            <li>
              <Link href="/terms-and-conditions">
                <a>Terms &amp; Conditions</a>
              </Link>
            </li>
            <li>
              <Link href="/privacy-policy">
                <a>Privacy Policy</a>
              </Link>
            </li>
          </ul>
        </FooterMainNav>
        <FooterMainNav>
          <h4>Members</h4>
          <ul className="list-unstyled">
            <li>
              <Link href="/members">
                <a>Members Area</a>
              </Link>
            </li>
            <li>
              <Link href="/account/settings">
                <a>Account Settings</a>
              </Link>
            </li>
          </ul>
        </FooterMainNav>
      </FooterMain>
      <FooterSub>
        <FooterSubCopyright>
          <a href="https://www.divergedigital.uk" style={{ color: `${vars.colors.black}` }} rel="designer">
            <img style={{ width: '70px', display: 'inline-block' }} src="/static/logo-diverge.svg" alt="Web Design by Diverge" />
          </a>
        </FooterSubCopyright>
      </FooterSub>
    </Root>
  );
};

export default SiteFooter;

// Styles

const Root = styled('footer')`
  position: relative;
`;

const Sep = styled('div')`
  height: 5px;
  background: linear-gradient(15deg, ${vars.colors.tertiary.mid}, ${vars.colors.primary.mid});
`;

const FooterMain = styled('div')`
  padding: ${vars.spacing[5]} ${vars.spacing[4]} ${vars.spacing[4]} ${vars.spacing[4]};
  justify-content: flex-end;
  background-color: ${vars.colors.secondary.darkest};
  color: ${vars.colors.secondary.offwhite};
  a {
    color: ${vars.colors.secondary.offwhite};
    &:hover {
      color: ${vars.colors.white};
    }
  }
  @media screen and (min-width: ${vars.break.lg}) {
    display: flex;
  }
`;
const FooterMainDesc = styled('div')`
  margin-right: auto;
  margin-bottom: ${vars.spacing[3]};
  p {
    max-width: 320px;
  }
  @media screen and (min-width: ${vars.break.lg}) {
    margin-bottom: 0;
  }
`;
const FooterMainNav = styled('nav')`
  margin-bottom: ${vars.spacing[3]};
  h4 {
    color: ${vars.colors.white};
    margin-bottom: ${vars.spacing[1]};
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
  }
  li a {
    display: block;
    padding: ${vars.spacing[0]} 0;
  }
  @media screen and (min-width: ${vars.break.lg}) {
    margin-bottom: 0;
    margin-left: 80px;
    margin-right: ${vars.spacing[3]};
  }
`;

const FooterSub = styled('div')`
  padding: ${vars.spacing[2]};
  justify-content: space-between;
  align-items: center;
  background-color: ${vars.colors.secondary.darkest};
  @media screen and (min-width: ${vars.break.lg}) {
    display: flex;
  }
`;

const FooterSubCopyright = styled('div')`
  font-size: 0.8em;
  margin: 0 auto;
  @media screen and (min-width: ${vars.break.lg}) {
    margin-right: 0;
  }
`;
