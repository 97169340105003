import React from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import { BgImg, Section, Container, Cols, Col } from '@elwell/blocko';

import { vars } from '../lib/theme';

import Button from './Button';

const FooterCta = ({ className }) => {
  return (
    <Section
      css={css`
        position: relative;
        top: ${vars.spacing[3]};
      `}
    >
      <Container space={1}>
        <Box>
          <Cols vAlign="center" space={1}>
            <Col>
              <Title>Get your FREE Guitar toolkit</Title>
              <p>Click here to access your free guitar toolkit, containing some of our most popular lessons as well as a studio backing track bundle worth $99!</p>
            </Col>
            <Col>
              <Link href="/free-guitar-toolkit">
                <a>
                  <Button theme="success" block>
                    Get your free toolkit >>
                  </Button>
                </a>
              </Link>
            </Col>
          </Cols>
        </Box>
      </Container>
    </Section>
  );
};

export default FooterCta;

// Styles

const Box = styled('div')`
  padding: ${vars.spacing[3]};
  background-color: ${vars.colors.secondary.darker};
  background: linear-gradient(15deg, ${vars.colors.secondary.darker}, ${vars.colors.secondary.mid});
  color: ${vars.colors.secondary.offwhite};
  border-radius: ${vars.borderRadius[2]};
`;

const Title = styled('h3')`
  color: ${vars.colors.white};
`;
