import React from 'react';
import styled, { css } from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import { vars } from '../lib/theme';

const LessonMeta = ({ className, videoCount, duration }) => {
  return (
    <Root className={className}>
      {videoCount && <Item>{videoCount} Videos</Item>}
      {duration && <Item>{duration} Mins</Item>}
    </Root>
  );
};

export default LessonMeta;

// Styles

const Root = styled('ul')`
  margin: 0;
  padding: 0;
  list-style-type: none;
  line-height: 1.2;
`;
const Item = styled('li')`
  font-family: ${vars.fonts.headings};
  line-height: 1;
  display: inline-block;
  &:after {
    display: inline-block;
    content: '·';
    margin-left: ${vars.spacing[0]};
    margin-right: ${vars.spacing[0]};
  }
  &:last-of-type {
    margin-right: 0;
    &:after {
      display: none;
    }
  }
`;
