const LoadingError = props => {
  const serverErrorMsg = props.error && props.error.message ? props.error.message.replace('GraphQL error: ', 'Error: ') : 'Error!';
  const customErrorMsg = props.message;
  const errorMsg = customErrorMsg ? customErrorMsg : serverErrorMsg;
  return (
    <div style={{ padding: '40px', textAlign: 'center' }}>
      <b>{errorMsg}</b>
      {/* {console.log(props.error)} */}
    </div>
  );
};
export default LoadingError;
